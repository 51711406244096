<template>
  <div
    v-if="hasTags"
    class="coupon-info"
  >
    <!-- <div class="coupon-info"> -->
    <!-- 新到提示,special_tips为1时，展示券面新到标签 -->
    <div
      v-if="isNew"
      class="new-tag"
    >
      {{ language?.SHEIN_KEY_PC_30712 }}
      <!-- 分割点，需要根据类型判断，如果有主体或者类型，显示分割点 -->
    </div>
    <span
      v-if="isNew && (cIcon || iconPay || couponInfoType)"
      class="dot"
    ></span>
    <!-- 主体 -->
    <div
      v-if="cIcon"
      :class="[cIcon && 'icon-max-w', 'coupon-info__main']"
    >
      <img
        :src="cIcon"
        alt="Tag Icon"
        class="icon"
      />
    </div>
    <!-- 分割点，需要根据类型判断，如果没有主体或类型则不显示 -->
    <span
      v-if="cIcon && (couponInfoType || iconPay)"
      class="dot"
    ></span>
    <!-- 支付类型 -->
    <div
      v-if="iconPay"
      :class="['icon-max-w', 'coupon-info__iconPay']"
    >
      <span class="coupon-info__main_text">
        {{ language?.SHEIN_KEY_PC_30709 }}
      </span>
      <img
        :src="iconPay"
        alt="Tag Icon"
        class="pay_img"
      />
    </div>
    <span
      v-if="iconPay && couponInfoType"
      class="dot"
    ></span>
    <!-- 券类型 -->
    <span
      v-if="couponInfoType"
      class="coupon-info__type"
    >
      {{ couponInfoType }}
    </span>
  </div>
</template>
<script>
export default {
  name: 'CouponInfo'
}
</script>
<script setup>
import { inject, computed } from 'vue'

const data = inject('couponDataInfo')
const language = inject('couponLanguage')
const hasTags = inject('hasTags')
const couponInfoType = inject('couponInfoType')
const cIcon = inject('cIcon')

const isNew = computed(() => data?.isNew)
const iconPay = computed(() => data?.iconPay)

</script>

<style lang="less" scoped>
.base-font(@size-factor) {
  font-family: 'SF UI Text', sans-serif;
  font-weight: 400;
  font-size: @size-factor;
}
.coupon-info {
  background-color: var(--left-top-tag-color);
  padding: 0px 5px 0px 6px;
  border-radius: 3.5px 0px 4px 0px;
  max-width: calc(100% - 55px);
  color: var(--secondary-color);
  float: left;
  height: 20px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  .icon-max-w {
    max-width: initial;
  }
  &__main {
    line-height: 20px;
    margin-top: -2.8px;
    &_text {
      .base-font(12px);
      margin-right: 2.5px;
    }
    .icon {
      max-width: 78px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  &__iconPay{
    &_text {
      .base-font(12px);
      margin-right: 2.5px;
    }
  }
  &__type {
    .base-font(12px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 20px;
  }
  .dot {
    border-radius: 32px;
    background: var(--secondary-color);
    opacity: 0.3;
    width: 3px;
    height: 3px;
    display: inline-block;
    margin: 0 4px;
  }
}
.new-tag {
  color: #3cbd45;
  text-transform: capitalize;
  .base-font(12px);
  font-weight: 800;
}
.pay_img {
  height: 15px;
}
</style>
