<template>
  <!-- 单档劵 -->
  <div
    class="core-info__sol-mall"
  >
    <h2
      ref="title"
      class="core-info__title"
    >
      <span class="price">{{ discount }}</span>
      <!-- 是否赠品，Membership -->
      <span
        v-if="rules[0]?.discountExtra"
        class="gift"
      >
        {{ rules[0]?.discountExtra }}
      </span>
    </h2>
    <!-- 相关信息（单行、多行） -->
    <!-- 单档单行用class-few，单档多行用class-more -->
    <div
      ref="textContainer"
      :class="[
        'core-info__other',
        'core-info__few'
        // isTextLong ? 'core-info__more' : 'core-info__few'
      ]"
    >
      <!-- 使用门槛 -->
      <div
        v-if="rules[0]?.threshold"
        class="apply-orders"
      >
        <span>{{ rules[0]?.threshold }}</span>
      </div>
      <!-- 分割线 -->
      <span
        v-if="!isTextLong && (expectedSavedMoney || discountMaxLimit)"
        class="core-info__split-line"
      ></span>
      <CouponInfo
        :is-multi="false"
        :data="data"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'NotMultiCoupon'
}
</script>
<script setup>
import { ref, onMounted, computed } from 'vue'
import CouponInfo from './CouponInfo'

let isTextLong = ref(false)
let textContainer = ref(null)

//props
const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

const expectedSavedMoney = computed(() => props.data?.expectedSavedMoney)
const discountMaxLimit = computed(() => props.data?.discountMaxLimit)
const rules = computed(() => props.data?.rules)
const { GB_cssRight } = gbCommonInfo
const discount = computed(() => {
  const res = GB_cssRight ? rules?.value?.[0]?.discount?.replace(/(-?)(\d+(\.\d+)?)(%)/, '$4$2$1') :  rules?.value?.[0]?.discount
  return res || ''
})
onMounted(() => {
  heightStyle(20)
})

//判断文本是否超过一行
const heightStyle = num => {
  let height = textContainer.value?.clientHeight || 0
  if (height > num) {
    isTextLong.value = true
  } else {
    isTextLong.value = false
  }
}

</script>

<style lang="less" scoped>
.base-font(@size-factor) {
  font-family: 'SF UI Text', sans-serif;
  font-weight: 400;
  font-size: @size-factor;
}
.core-info {
  &__title {
    display: inline-flex;
    align-items: baseline;
    gap: 2px 4px;
    align-self: stretch;
    flex-wrap: wrap;
    max-width: 100%;
    color: var(--core-color);
    word-wrap: break-word;
    font-family: 'SF UI Display';
    font-weight: 700;
    font-size: 28px;
    margin-bottom: 4px;
    text-transform: none;
    .gift {
      font-size: 16px;
      text-transform: none;
    }
  }
  &__other {
    .apply-orders,
    .apply-Capped,
    .apply-Save {
      .base-font(12px);
      line-height: normal;
      word-wrap: break-word;
      span {
        color: var(--secondary-color);
        // font-weight: 300;
      }
    }
  }
  &__split-line {
    opacity: 0.3;
    background: var(--secondary-color);
    width: 1px;
    height: 9px;
  }
  &__few {
    display: flex;
    flex-wrap: wrap;
    gap: 3.5px;
    align-items: center;
  }
  .showy {
    color: var(--core-color);
    font-weight: 700;
  }
}
</style>
