<template>
  <div 
    class="supplementary-info"
    :style="containerStyle"
  >
    <div :class="couponInfo.needMask ? 'supplementary-info__wrapper': ''">
      <!-- 有效期 -->
      <div style="display: flex;align-items:center;">
        <CouponDate 
          :date-info="couponDateInfo" 
          :language="language"
          @date-area-click="handleButtonClick({ assistInformationType: curretAssistInformationType, couponInfo, })"
        />
        <Icon 
          v-if="couponInfo?.specialScene !== 'detailAbPrice'"
          name="sui_icon_more_down_14px" 
          size="14px" 
          :style="[
            { border: '0.5px solid #DDD8D7' },
            { cursor: 'pointer' }, 
            !isExpand ? { transform: 'rotate(0deg)' } : { transform: 'rotate(180deg)'}
          ]"
          @click="handleButtonClick({ assistInformationType: curretAssistInformationType, couponInfo, })"
        />
      </div>
      <!-- 辅助信息: 适用范围 + 支付限制运输限制 + 优惠梯度说明 + code码 + 指定产品包 -->
      <template v-if="isExpand">
        <template 
          v-for="(item, index) in couponInfo.optionTipList" 
          :key="index"
        >
          <div 
            class="supplementary-info__item"
            :style="couponInfo.waterMark !== WATER_MARK_TYPE.unshow ? { maxWidth: 'calc(100% - 64px)' } : {}"
          >
            {{ item.text }}
          </div>
        </template>
      </template>
    </div>
    <!-- 水印 -->
    <WaterMark
      v-if="!!couponInfo.waterMark && couponInfo.waterMark !== WATER_MARK_TYPE.unshow"
      :watermark-text="waterMarkText"
      :watermark-img-url="watermarkImgUrl"
    />
  </div>
</template>

<script>
export default {
  name: 'SupplementaryInfo',
}
</script>

<script setup>
// 优惠券附加信息入口
import { ref, defineProps, computed, watch, defineAsyncComponent } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { ASSIST_INFORMATION_TYPE } from '../../constants.js'
import { getWatermarkText } from '../../utils'
// import { EventBus } from '@/public/src/pages/common/event-bus.js'

import CouponDate from './CouponDate.vue'
const WaterMark = defineAsyncComponent(() => import('./WaterMark.vue'))

const { WATER_MARK_TYPE } = require('@/controllers/common-coupon/constants.js')

const props = defineProps({
  // 优惠券数据
  couponInfo: {
    type: Object,
    default: () => {}
  },
  language: {
    type: Object,
    default: () => {}
  },
  // 接入方传入的配置
  couponConfig: {
    type: Object,
    default: () => {}
  },
  // 传入空或者当前需要展开的优惠券id
  expanded: {
    type: String,
    default: ''
  }
})

const assistInformationTypeArr = [
  ASSIST_INFORMATION_TYPE.fold,
  ASSIST_INFORMATION_TYPE.unfold,
]

const curretAssistInformationType = ref(props?.couponConfig?.assistInformationType)
// 初始态是否展开由couponConfig.assistInformationType决定
const isExpand = ref(props?.couponConfig?.assistInformationType === ASSIST_INFORMATION_TYPE.unfold)

const containerStyle = computed(() => ({
  background: 'var(--bg-color)',
  border: `0.5px solid var(--border-color)`,
  borderTop: 'none',
  borderRadius: '0 0 4px 4px',
}))

const couponDateInfo = computed(() => ({
  startDate: props?.couponInfo?.startDate,
  endDate: props?.couponInfo?.endDate,
}))

const waterMarkText = computed(() => getWatermarkText(props?.couponConfig?.waterMark, props?.language))
const watermarkImgUrl = computed(() => {
  if (props?.couponConfig?.waterMark === WATER_MARK_TYPE.isClaimed) {
    // 已领取 水印戳
    return 'https://img.ltwebstatic.com/images3_ccc/2024/05/08/3b/17151367483a17f9dc99390d2a8b2f0823f37e1ac4.webp'
  }
  return 'https://img.ltwebstatic.com/images3_ccc/2024/05/27/5f/17168093770a8d03b4c942093b4bc9912815441c6d.webp'
})

// watch(() => props.expanded, (val) => {
//   isExpand.value = val == props.couponInfo?.id
// })

watch(() => isExpand.value, (val) => {
  if (val) {
    curretAssistInformationType.value = ASSIST_INFORMATION_TYPE.unfold
  } else {
    curretAssistInformationType.value = ASSIST_INFORMATION_TYPE.fold
  }
})

const handleButtonClick = ({ assistInformationType, couponInfo, }) => {
  // 更新折叠状态
  curretAssistInformationType.value = assistInformationTypeArr.filter(item => item !== assistInformationType)[0]
  // 组件内控制展开状态
  isExpand.value = !isExpand.value
  // EventBus.emit('button-click', {
  //   assistInformationType: curretAssistInformationType.value,
  //   couponInfo, 
  //   from: CLICK_EVENT_FROM.couponAssistInformationType,
  // })
}

</script>

<style lang="less">
.supplementary-info {
  z-index: 2;
  padding: 7px 14px 12px 14px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: var(--supplementary-info-text-color);
  font-family: 'SF UI Display';
  
  // 蒙层
  &__wrapper::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--mask-color);
    z-index: 1;
    pointer-events: none;
  }

  &__item {
    font-size: 12px;
    font-weight: 400;
    margin-top: 4px;
  }
}
</style>
