<template>
  <!-- 购物车场景，只透出最大省额 -->
  <!-- 最大优惠限制 -->
  <div
    v-if="([ACTIVITY_FROM.cart].includes(couponConfig?.activityFrom) || !expectedSavedMoney) && discountMaxLimit"
    class="apply-Capped"
  >
    <!-- 单档劵不加class，多档core-info__text -->
    <span :class="[isMulti && 'core-info__text']">
      {{ language?.SHEIN_KEY_PC_30596?.replace('{0}', '') }}
      {{ discountMaxLimit }}
    </span>
  </div>
  <!-- 预计节省金额 -->
  <div
    v-else
    class="apply-Save"
  >
    <span class="showy">{{ expectedSavedMoney }}</span>
    <!-- 最大金额 -->
    <span
      v-if="discountMaxLimit"
      :class="[isMulti && 'core-info__text']"
    >
      ({{ language?.SHEIN_KEY_PC_30731?.replace('{0}', discountMaxLimit) }})
    </span>
    <!-- 美国站气泡 -->
    <Icon
      v-if="showBouble"
      class="double-icon"
      name="sui_icon_doubt_14px_1"
      size="12px"
      color="#959595"
      @click="handleButtonClick({ couponInfo: data })"
    />
  </div>
</template>
<script>
export default {
  name: 'CouponInfo'
}
</script>
<script setup>
import { computed, inject, defineProps } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { EventBus } from '@/public/src/pages/common/event-bus.js'
import { CLICK_EVENT_FROM } from '../../constants.js'
import { ACTIVITY_FROM } from '@/controllers/common-coupon/constants.js'
const {
  COUPON_LAYER_BUTTON_TYPE
} = require('@/controllers/common-coupon/constants.js')

//props
const props = defineProps({
  isMulti: {
    type: Boolean,
    default: false
  },
  data: {
    type: Object,
    default: () => {}
  }
})

const language = inject('couponLanguage')
const couponConfig = inject('couponConfig')

const expectedSavedMoney = computed(() => props.data?.expectedSavedMoney)
const discountMaxLimit = computed(() => props.data?.discountMaxLimit)
const buttonInfo = computed(() => props.data?.buttonInfo)

const isChecked = computed(() => {  
  switch (buttonInfo.value) {
    case COUPON_LAYER_BUTTON_TYPE.availableAndSelected:
    case COUPON_LAYER_BUTTON_TYPE.disabledAndSelected:
      return true
    default:
      return false
  }
})

const showBouble = computed(() => {
  return props.data?.cartIds && !!props.data.cartIds.length && isChecked.value  
})


// 气泡点击事件
const handleButtonClick = ({ couponInfo }) => {
  EventBus.emit('button-click', {
    couponInfo,
    from: CLICK_EVENT_FROM.couponHelp
  })
}
</script>
<style lang="less" scoped>
.base-font(@size-factor) {
  font-family: 'SF UI Text', sans-serif;
  font-weight: 400;
  font-size: @size-factor;
}
.apply-orders,
.apply-Capped,
.apply-Save {
  .base-font(12px);
  display: flex;
  align-items: center;
  line-height: normal;
  word-wrap: break-word;
  span {
    color: var(--secondary-color);
  }
  .double-icon {
    margin-left: 2px;
  }
}
.showy {
  /* stylelint-disable-next-line declaration-no-important */
  color: var(--core-color) !important;
  font-weight: 700;
}

.core-info__text {
  color: var(--secondary-color);
  .base-font(12px);
  line-height: normal;
  word-wrap: break-word;
}
</style>
