<template>
  <div
    v-if="coupon_amount && times && times > 1"
    class="coupon-count"
  >
    x{{ times > 999 ? '999+' : times || '' }}
  </div>
</template>
<script>
export default {
  name: 'CouponCount'
}
</script>
<script setup>
import { inject } from 'vue'

let originData = inject('couponDataInfo')
//解构数据
let { times = '', coupon_amount = 1 } = originData || {}
</script>

<style lang="less" scoped>
.coupon-count {
  background-color: var(--countdown-bg-color);
  color: var(--secondary-color);
  padding: 0px 6px 0px 7px;
  height: 14px;
  font-family: 'SF UI Text', sans-serif;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 400;
  border-radius: 0px 3.5px 0px 4px;
  float: right;
}
</style>
