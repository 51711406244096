<template>
  <div class="core-info">
    <!-- 单档 -->
    <NotMultiCoupon
      v-if="!isMulti"
      :data="data"
    />
    <!-- 多档 -->
    <MultiCoupon
      v-else
      :data="data"
    />
  </div>
</template>
<script>
export default {
  name: 'CoreInfo'
}
</script>

<script setup>
import MultiCoupon from './MultiCoupon'
import NotMultiCoupon from './NotMultiCoupon'

import { ref, onMounted, computed } from 'vue'
//props
const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})
let isMulti = ref(false)
const rules = computed(() => props?.data?.rules)
onMounted(() => {
  //判断是否多档
  isMulti.value = rules.value?.length > 1
})
</script>
