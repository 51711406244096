<template>
  <div 
    class="coupondate-container"
    @click="handleClick"
  >
    <!-- 倒计时 -->
    <div
      v-if="isCouponValid && isNeedCountdown && timeTips"
      class="coupondate-container__countdown"
    >
      <span class="coupondate-container__countdown-text">
        {{ template('',language?.SHEIN_KEY_PC_30725) }}
      </span>
      <div
        class="coupondate-container__countdown-time"
        v-html="!isCountdownEnd ? timeTips : endTimeTips"
      ></div>
    </div>
    <!-- 有效日期范围 -->
    <div v-else>
      <span class="coupondate-container__valid-period">
        {{ validPeriod }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CouponDate',
}
</script>

<script setup>
import { ref, defineProps, defineEmits, onMounted, computed, inject } from 'vue'
import { CountDown, debounce, timeTransformer, template, htmlDecode } from '@shein/common-function'
import { MESSAGE_DATE } from 'public/src/pages/common/biz_helper/timer_format/dictionary'

const props = defineProps({
  dateInfo: {
    type: Object,
    default: () => {}
  },
  language: {
    type: Object,
    default: () => {}
  },
})

const emits = defineEmits(['date-area-click'])

let isCouponValid = inject('isCouponValid')

const beginTime = ref(0)
const countTimer = ref(new CountDown())
const isCountdownEnd = ref(false)
const countdownStartRequire = ref('48h') // 产品特殊要求的倒计时开始时间，默认为48 h

const timeTips = computed(() => {
  const { H, M, S } = countTimer.value?.timeObj || {}
  let pTxt = txt => `<p>${txt}</p>`
  return htmlDecode({ text: `${pTxt(H)}<span>:</span>${pTxt(M)}<span>:</span>${pTxt(S)}`, isHtml: true })
})

const endTimeTips = computed(() => {
  let pTxt = '<p>00</p>'
  return htmlDecode({ text: `${pTxt}<span>:</span>${pTxt}<span>:</span>${pTxt}`, isHtml: true })
})

const isNeedCountdown = computed(() => {
  if (!props.dateInfo?.startDate) return false // 无开始时间，不需要倒计时（商详场景）
  const oneHourTimestamp = 60 * 60 * 1000
  const endRange = (props.dateInfo?.endDate * 1000 - Date.now()) / oneHourTimestamp
  return endRange <= parseInt(countdownStartRequire.value)
})

const validPeriod = computed(() => {
  if (!props.dateInfo?.startDate) return `${template(handleDate(props.dateInfo?.endDate), props.language?.SHEIN_KEY_PC_30725)}`
  return `${handleDate(props.dateInfo?.startDate)} ~ ${handleDate(props.dateInfo?.endDate)}`
})

const handleDate = (date) => {
  if (!date) return date
  const time = date * 1000
  return `${timeTransformer({
    time,
    sDateMap: MESSAGE_DATE,
  })} ${timeTransformer({ time, defaultCode: 'G-2' })}`
}

const init = () => {
  // 倒计时开始时间
  beginTime.value = props.dateInfo?.endDate * 1000 - Date.now()
  if (beginTime.value <= 0) {
    beginTime.value == 0
  }

  // 初始化倒计时
  countTimer.value.start({
    seconds: beginTime.value / 1000,
    endFunc: debounce({
      func: () => {
        isCountdownEnd.value = true
      }
    })
  })
}

const handleClick = () => {
  emits('date-area-click')
}

onMounted(() => {
  init()
})

</script>

<style lang="less">
.coupondate-container {
  display: flex;
  align-items: center;
  max-width: calc(100% - 64px);
  cursor: pointer;
  
  &__valid-period {
    margin-right: 6px;
  }
  
  &__countdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__countdown-text {
    font-size: 12px;
    color: var(--secondary-color);
    font-weight: 400;
  }
  &__countdown-time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--secondary-color);
    margin-left: 4px;
    margin-right: 6px;
   
    p {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px;
      min-width: 21px;
      height: 18px;
      background-color: var(--countdown-bg-color);
      text-align: center;
      border-radius: 2px;
      font-size: 12px;
      font-weight: 600;
    }
    span {
      font-size: 12px;
      font-weight: 400;
      margin: 0 3px;
    }
  }
}
</style>
