<template>
  <div class="imnormal">
    <template v-if="type === 'empty'">
      <span class="img-span empty"></span>
      <p>{{ language.SHEIN_KEY_PC_17255 }}</p>
    </template>
    <template v-if="type === 'loading'">
      <div class="spining">
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
      </div>
      <p>{{ language.SHEIN_KEY_PC_14541 }}</p>
    </template>
    <template v-if="type === 'error'">
      <span class="img-span error"></span>
      <h3>{{ language.SHEIN_KEY_PC_17312 }}</h3>
      <p>{{ language.SHEIN_KEY_PC_16740 }}</p>
      <span
        class="try-btn"
        @click="refresh"
      >{{
        language.SHEIN_KEY_PC_17377
      }}</span>
    </template>
  </div>
</template>

<script>
const { IS_RW } = gbCommonInfo
export default {
  props: {
    type: {
      type: String,
      default: '',
    },
    language: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      IS_RW,
    }
  },
  methods: {
    refresh() {
      this.$emit('refresh')
    },
  },
  emits: ['refresh'],
}
</script>
