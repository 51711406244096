<template>
  <li :class="{ invalid: !(itemData.stock > 0 && itemData.is_on_sale == 1) }">
    <div
      class="img"
      @click="getGoodsUrl"
    >
      <!-- <img :src="itemData.goods_img" /> -->
      <CropImageContainer
        :img-src="goodsImg"
        fixed-ratio="3-4"
        style="width: 100%"
      />
      <div
        v-if="hasDiscount && itemData.unit_discount"
        class="discount-lab"
      >
        -{{ itemData.unit_discount }}%
      </div>
      <div
        class="she-black-btn"
        @click.stop="popQuickView"
      >
        {{ language.SHEIN_KEY_PC_15753 }}
      </div>
      <div
        v-if="!(itemData.stock > 0 && itemData.is_on_sale == 1)"
        class="sold-out"
      >
        <Icon
          name="sui_icon_soldout_36px"
          size="36px"
        />
        {{ language.SHEIN_KEY_PC_14617 }}
      </div>
    </div>
    <p
      class="name"
      @click="getGoodsUrl"
    >
      {{ itemData.goods_name }}
    </p>
    <p :class="{ price: true, 'is-red': hasDiscount }">
      {{ itemData.salePrice.amountWithSymbol }}
      <del v-if="hasDiscount">{{ itemData.retailPrice.amountWithSymbol }}</del>
    </p>
    <div
      v-if="showQuickShip"
      class="gift-quick-ship"
    >
      <s-label type="success">
        {{ language.SHEIN_KEY_PC_23485 }}
      </s-label>
    </div>
  </li>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue3'
import { CropImageContainer } from 'public/src/pages/components/CropImageContainer/index.js'
import {
  getProductDetailUrl,
  getImageRatio
} from 'public/src/pages/common/utils/index.js'

export default {
  name: 'ProductionVue',
  components: {
    CropImageContainer,
    Icon
  },
  props: {
    itemData: {
      type: Object,
      default() {
        return {}
      }
    },
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    showQuickShipTag: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    goodsImg() {
      return this.itemData.goods_img
    },
    hasDiscount() {
      return this.itemData.retailPrice.amount !== this.itemData.salePrice.amount
    },
    showQuickShip() {
      return this.showQuickShipTag && this.itemData.quick_ship ? true : false
    }
  },
  methods: {
    async getGoodsUrl() {
      const { stock, goods_url_name, goods_id, cat_id, is_on_sale } =
        this.itemData
      if (!(stock > 0 && is_on_sale == 1)) {
        return
      }
      const url = await getProductDetailUrl({
        imgSrc: this.goodsImg,
        goods_url_name,
        goods_id,
        cat_id
      })
      window.open(url, '_blank')
    },
    async popQuickView() {
      const imgRatio = await getImageRatio(this.goodsImg)
      this.$emit('click', {
        ...this.itemData,
        imgRatio
      })
    }
  },
  emits: ['click']
}
</script>
<style lang="less" scoped>
.gift-quick-ship {
  margin-top: 8px;
  label {
    .text-overflow();
    max-width: 100%;
  }
}
</style>
