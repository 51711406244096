import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '2-38' })

export const clickCouponComponent = (data, config) => {
  daEventCenter.triggerNotice({
    daId: '2-38-2',
    extraData: {
      activity_from: config?.activityFrom,
      coupon_id: data?.id,
      coupon_code: data?.coupon,
      type_id: data?.typeId,
      act_id: config?.buttonInfo,
      category_label: data?.categoryId?.length,
    }
  })
}

export const clickAddCouponComponent = (data, config) => {
  const couponGoodsList = config?.addModule?.content?.couponGoodsList || []

  daEventCenter.triggerNotice({
    daId: '2-38-4',
    extraData: {
      activity_from: config?.activityFrom,
      type_id: data?.style,
      act_id: data?.btnType,
      coupon_id: data?.id,
      coupon_code: data?.coupon,
      if_useful: data?.satisfiedRange ? 1 : 0,
      sku_id: couponGoodsList.filter(item => item?.goodsSn)?.map(item => item?.goodsSn).join(','),
    }
  })
}
