const { COUPON_COLOR } = require('./constants')
const {
  COUPON_COLOR_TYPE,
  WATER_MARK_TYPE,
  COUPON_LAYER_BUTTON_TYPE
} = require('@/controllers/common-coupon/constants.js')

export const iconType = {
  normal_coupon: 'discountcoupons',
  saver_coupon: 'sheinsavercoupons',
  club_coupon: 'sheinclubcoupons',
  shipping_coupon: 'shippingcoupons'
}

export const getImgUrl = (sheinType, colorType) => {
  if (sheinType === 'shein_club' && colorType !== 'expired_coupon') {
    return 'https://img.ltwebstatic.com/images3_ccc/2024/04/16/63/1713239463c768e76695e9b843f9abb7e3d69333f7.webp'
  } else if (sheinType === 'shein_saver' && colorType !== 'expired_coupon') {
    return 'https://img.ltwebstatic.com/images3_ccc/2024/04/16/ee/1713252644988aac17ee8e5762a35ab135c4838179.webp'
  } else if (sheinType === 'shein_saver' && colorType === 'expired_coupon') {
    //过期的超省卡
    return 'https://img.ltwebstatic.com/images3_ccc/2024/04/20/2f/17135924650723a6c446223fff9a6361c6b5821e52.webp'
  } else if (sheinType === 'shein_club' && colorType === 'expired_coupon') {
    //过期的付费券
    return 'https://img.ltwebstatic.com/images3_ccc/2024/04/20/9b/171359222104a54140913d8917f5834a9b5cdd1530.webp'
  }
}
//枚举值: category_coupon/store_coupon/shipping_coupon/new_user_coupon
export const businessType = (type, language) => {
  switch (type) {
    case 'category_coupon':
      return language?.SHEIN_KEY_PC_30598 || ''
    case 'store_coupon':
      return language?.SHEIN_KEY_PC_30721 || ''
    case 'shipping_coupon':
      return language?.SHEIN_KEY_PC_30722 || ''
    case 'special_coupon':
      return language?.SHEIN_KEY_PC_30720 || ''
  }
}
//configType 1-最佳优惠提示，2-可叠加标签
export const getInformText = (promptInfo = [], language) => {
  //promptInfo中有bestChoice的时候，并且CouponConfig.inform也为1是有优惠提示
  if (promptInfo?.includes('best_choice')) {
    return language?.SHEIN_KEY_PC_30599 || ''
  } else if (promptInfo?.includes('stackable')) {
    //promptInfo中有stackable的时候，并且CouponConfig.inform为2是可叠加
    return language?.SHEIN_KEY_PC_30710 || ''
  }
}

export const handleCouponColor = couponItem => {
  let colorConfig = {}
  let buttonType = couponItem?.buttonInfo

  switch (couponItem.couponColorType) {
    case COUPON_COLOR_TYPE.shippingCoupon:
      colorConfig =
        buttonType !== COUPON_LAYER_BUTTON_TYPE.claim
          ? COUPON_COLOR.freeShippingCoupon
          : { ...COUPON_COLOR.freeShippingCoupon, btnColor: 'rgba(0, 0, 0, 0.05)' }
      break
    case COUPON_COLOR_TYPE.clubCoupon:
      colorConfig =
        buttonType !== COUPON_LAYER_BUTTON_TYPE.claim
          ? COUPON_COLOR.clubCoupon
          : { ...COUPON_COLOR.clubCoupon, btnColor: 'rgba(0, 0, 0, 0.05)' }
      break
    case COUPON_COLOR_TYPE.saverCoupon:
      colorConfig =
        buttonType !== COUPON_LAYER_BUTTON_TYPE.claim
          ? COUPON_COLOR.saverCoupon
          : { ...COUPON_COLOR.saverCoupon, btnColor: 'rgba(0, 0, 0, 0.05)' }
      break
    case COUPON_COLOR_TYPE.expiredCoupon:
      colorConfig =
        buttonType !== COUPON_LAYER_BUTTON_TYPE.claim
          ? COUPON_COLOR.expiredCoupon
          : { ...COUPON_COLOR.expiredCoupon, btnColor: 'rgba(0, 0, 0, 0.05)' }
      break
    default:
      colorConfig =
        buttonType !== COUPON_LAYER_BUTTON_TYPE.claim
          ? COUPON_COLOR.normalCoupon
          : { ...COUPON_COLOR.normalCoupon, btnColor: 'rgba(0, 0, 0, 0.05)' }
      break
  }

  return colorConfig
}

export const getWatermarkText = (type, language) => {
  switch (type) {
    case WATER_MARK_TYPE.isUsed:
      return language.SHEIN_KEY_PC_30765
    case WATER_MARK_TYPE.isExpired:
      return language.SHEIN_KEY_PC_30762
    case WATER_MARK_TYPE.isExpanded:
      return language.SHEIN_KEY_PC_30764
    case WATER_MARK_TYPE.isClaimed:
      return language.SHEIN_KEY_PC_30766
    case WATER_MARK_TYPE.isClaimedAll:
      return language.SHEIN_KEY_PC_30763
    case WATER_MARK_TYPE.isInvalid:
      return language.SHEIN_KEY_PC_31049
  }
}

export const getParentBgColor = elementId => {
  var childElement = document.getElementById(elementId)
  // 检查元素是否存在
  if (childElement) {
    // 获取元素的父容器
    var parentElement = childElement?.parentNode

    // 遍历所有祖先元素
    while (parentElement) {
      // 使用getComputedStyle()方法获取父容器的背景色
      var parentBgColor = window.getComputedStyle(parentElement).backgroundColor

      // 检查背景色是否不是透明的
      if (
        parentBgColor !== 'transparent' &&
        parentBgColor !== 'rgba(0, 0, 0, 0)'
      ) {
        return parentBgColor
      }
      // 移动到下一个祖先元素
      parentElement = parentElement?.parentNode
    }
  }
}
